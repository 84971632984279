window.addEventListener("DOMContentLoaded", (event) => {
  $('.js-table-line-link').each(function(index) {
    var tds = $(this).parents('tr.cursor-link').find('td:not(.actions)')
    var link = $(this).data('url');

    $(tds).on('click', function(e) {
      window.location = link;
    });
  });

  $('.datepicker').datepicker({
    language: 'fr-FR',
    format: 'dd/mm/yyyy',
    weekStart: 1
  });

  $('.timepicker-group input[type=text]').timepicker({
    timeFormat: 'HH:mm:ss',
    interval: 1,
    defaultTime: '',
    dynamic: false,
    dropdown: true,
    scrollbar: true
  });
});

class duplicateFields {
  // This executes when the function is instantiated.
  constructor() {
    this.iterateLinks()
  }

  iterateLinks() {
    // Use event delegation to ensure any fields added after the page loads are captured.
    document.addEventListener('click', e => {
      if (e.target && e.target.className == 'duplicate_fields text-decoration-none btn btn-outline-info btn-sm mt-2 col-10 col-sm-5 me-0 me-sm-4') {
        this.handleClick(e.target, e);
      }
    })
  }

  handleClick(link, e) {
    // Stop the function from executing if a link or event were not passed into the function.
    if (!link || !e) return
    // Prevent the browser from following the URL.
    e.preventDefault()
    // Find the parent wrapper for the set of nested fields.
    let fieldParent = link.closest('.nested-fields')
    let newFieldBtn = document.getElementById('js-add_fields_btn')
    if (!newFieldBtn) return
    let time = new Date().getTime()
    // Save the data id attribute into a variable. This corresponds to `new_object.object_id`.
    let linkId = newFieldBtn.dataset.id
    // Create a new regular expression needed to find any instance of the `new_object.object_id` used in the fields data attribute if there's a value in `linkId`.
    let regexp = linkId ? new RegExp(linkId, 'g') : null
    // Replace all instances of the `new_object.object_id` with `time`, and save markup into a variable if there's a value in `regexp`.
    let newFields = regexp ? newFieldBtn.dataset.fields.replace(regexp, time) : null
    // Add the new markup to the form if there are fields to add.
    newFields ? $(newFields).insertAfter($(link).parents('.nested-fields')) : null

    let startAtValue = $("[name*=start_at]", fieldParent).val()
    let endAtValue = $("[name*=end_at]", fieldParent).val()
    let titleValue = $("[name*=title]", fieldParent).val()
    let contentValue = $("[name*=content]", fieldParent).val()
    let linkValue = $("[name*=link]", fieldParent).val()

    this.loadTimepicker(time)

    $('#schedule_schedule_lines_attributes_' + time + '_start_at').val(startAtValue)
    $('#schedule_schedule_lines_attributes_' + time + '_end_at').val(endAtValue)
    $('#schedule_schedule_lines_attributes_' + time + '_title').val(titleValue)
    $('#schedule_schedule_lines_attributes_' + time + '_content').val(contentValue)
    $('#schedule_schedule_lines_attributes_' + time + '_link').val(linkValue)
  }

  loadTimepicker(nestedId) {
    $('#schedule_schedule_lines_attributes_' + nestedId + '_start_at').timepicker({
      timeFormat: 'HH:mm:ss',
      interval: 1,
      defaultTime: '09:00:00',
      dynamic: false,
      dropdown: true,
      scrollbar: true
    });

    $('#schedule_schedule_lines_attributes_' + nestedId + '_end_at').timepicker({
      timeFormat: 'HH:mm:ss',
      interval: 1,
      defaultTime: '',
      dynamic: false,
      dropdown: true,
      scrollbar: true
    });
  }
}

window.addEventListener("DOMContentLoaded", (event) => new duplicateFields())
